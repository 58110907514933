import upperCase from 'lodash/upperCase'
import kebabCase from 'lodash/kebabCase'
import {createStore} from 'redux'
import {createHookedOnReducer} from 'hooked-on-redux'


const reducer = createHookedOnReducer()
const store = createStore(reducer, {})

const createPublish = (store, machineNamespace = 'APP') =>
  (path, payload) =>
    store.dispatch({
      payload,
      path,
      type: `${machineNamespace}/${upperCase(kebabCase(path))}`
})

const publish = createPublish(store, 'HOOKED_ON_REDUX')

export {
  store as default,
  publish
}