import './App.css'
import { useState, useEffect } from 'react'
import { useHookedOnState } from 'hooked-on-redux'

const sayWord = word => {
  let utterance = new SpeechSynthesisUtterance()
  utterance.text = word
  window.speechSynthesis.speak(utterance)
}

const App = () => {
  const [screen] = useHookedOnState('screen', 'home')

  return (
    <div className='App'>
      {screen === 'home' && <Home />}
      {screen === 'practice' && <Practice />}
      {screen === 'words' && <Words />}
      {screen === 'finished' && <Finished />}
    </div>
  )
}

const Home = () => {
  const [, setScreen] = useHookedOnState('screen', 'home')
  return (
    <div className='Home'>
      <a className='logo-link' href="/"><img className='logo' src='/logo.webp' alt='logo' /></a>
      <button onClick={() => setScreen('practice')}>Practice</button>
      <button className="borderless" onClick={() => setScreen('words')}>Set Words</button>
    </div>
  )
}

const Finished = () => {
  const [, setScreen] = useHookedOnState('screen', 'home')

  useEffect(() => {
    const audio = new Audio('./tada.mp3')
    audio.play()
  }, [])

  return (
    <div className='Finished checker'>
      <h1>guud jerb!!!</h1>
      <button onClick={() => setScreen('practice')}>Keep Going!</button>
      <button className="borderless" onClick={() => setScreen('home')}>Go Home</button>
    </div>
  )
}

const Practice = () => {
  const [, setScreen] = useHookedOnState('screen', 'home')
  const [words, setWords] = useHookedOnState('words', ['apple', 'pear', 'banana'])

  const [shuffledWords, setShuffledWords] = useState([])
  const [textField, setTextField] = useState('')
  const [isChecking, setIsChecking] = useState(false)

  useEffect(() => {
    const savedWords = localStorage.getItem('words')
    const parsedSavedWords = JSON.parse(savedWords)
    if (parsedSavedWords) {
      setWords(parsedSavedWords)
    }
    const newWords = parsedSavedWords || words
    const sorted = newWords.sort(() => Math.random() - 0.5)
    setShuffledWords(sorted)
    sayWord(sorted[0])
  }, [])

  const nextWord = () => {
    const newWords = shuffledWords.slice(1)
    setIsChecking(false)
    setShuffledWords(newWords)
    setTextField('')
    console.log('newWords', newWords)
    if (newWords.length === 0) {
      setScreen('finished')
    } else {
      sayWord(newWords[0])
    }
  }

  const check = () => {
    setIsChecking(true)
    const audio = shuffledWords[0].toLowerCase() === textField.toLowerCase()
      ? new Audio('./yay.mp3')
      : new Audio('./womp.mp3')

    audio.play()
  }
  
  return (
    <div className='Practice'>
      <button className="borderless close-img" onClick={() => setScreen('home')}><img src="./close.webp" /></button>
      {!isChecking 
        ? <div className='entry'>
            <input type='text' 
              value={textField} 
              onChange={e => setTextField(e.target.value)} 
              spellCheck={false}
              autoCorrect='off'
              autoComplete='off'
              autoCapitalize='off'
              autoFocus={true}
            />
            <button className="borderless speaker-img" onClick={() => sayWord(shuffledWords[0])}><img src="./speaker.webp"/></button>
            <button className="no-margin-top" onClick={() => check()}>Check</button>  
          </div> 
        : <div>{shuffledWords[0].toLowerCase() === textField.toLowerCase() 
          ? <div className="checker">
              <h1>You got it!</h1>
              <button onClick={() => nextWord()}>Next</button>
            </div> 
          : <div className="checker">
              <h1>Almost!</h1> 
              <div className="correction">{shuffledWords[0]}</div>
              <div className="correction red">{textField}</div>
              <button className="margin-top-48" onClick={() => {
                setIsChecking(false)
                setTextField('')
                sayWord(shuffledWords[0])
              }}>Try Again</button>
            </div>
          }
      </div>}
    </div>
  )
}

const Words = () => {
  const [, setScreen] = useHookedOnState('screen', 'home')
  const [words, setWords] = useHookedOnState('words', ['apple', 'pear', 'banana'])

  useEffect(() => {
    const savedWords = localStorage.getItem('words')
    if (savedWords) {
      setWords(JSON.parse(savedWords))
    }
  }, [])

  const save = words => {
    localStorage.setItem('words', JSON.stringify(words))
    setWords(words)
  }

  // Function to handle text field changes
  const handleTextChange = (index, e) => {
    const newStrings = [...words]
    newStrings[index] = e.target.value
    save(newStrings)
  }

  // Function to handle entry deletion
  const handleDelete = (index) => {
    const newStrings = [...words]
    newStrings.splice(index, 1)
    save(newStrings)
  }

  // Function to add a new entry
  const handleAddNew = () => {
    const newStrings = [...words, '']
    save(newStrings)
  }

  return (
    <div>
      <button className="borderless" onClick={() => setScreen('home')}><img className="back-arrow" src="./back.webp"/>&nbsp;Done</button>
      <div className="Words">
        <h1>Word List</h1>
        {words.map((str, index) => (
          <div key={index}className="wordlist-field">
            <input
              type="text"
              value={str}
              onChange={(e) => handleTextChange(index, e)}
            />
            <button className="borderless marginless" onClick={() => handleDelete(index)}><img className="trash-img" src="./trash.webp"/></button>
          </div>
        ))}
        <button onClick={handleAddNew}>+ Add Word</button>
      </div>
    </div>
  )
}


export default App
